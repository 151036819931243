<template>
  <div class="opt-bonuses">
    <div class="container">
      <div class="opt-bonuses__header">
        <h2 class="title">{{ $t("bonus") }}</h2>
        <span>{{ $t("bonusContent") }}</span>
      </div>
      <div class="opt-bonuses__info">
        <div class="opt-bonuses__items">
          <div
            class="opt-bonuses__item"
            v-for="(bonus, index) in $t('bonuses')"
            :key="index"
          >
            <div class="opt-bonuses__item-title">{{ bonus.title }}</div>
            <p class="opt-bonuses__item-descr">{{ bonus.content }}</p>
          </div>
        </div>
        <div class="opt-bonuses__ps">
          <div class="opt-bonuses__ps-item">
            {{ $t("optBonuses_1") }}
          </div>
          <div class="opt-bonuses__ps-item">
            {{ $t("optBonuses_2") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptBonuses",
};
</script>
